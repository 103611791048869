import React from "react";
import theme from "theme";
import { Theme, Text, Section, Structure, Button, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				InkMasters Tattoo Studi
			</title>
			<meta name={"description"} content={"Ласкаво просимо до InkMasters Tattoo Studio: де мистецтво стає частиною вас"} />
			<meta property={"og:title"} content={"InkMasters Tattoo Studi"} />
			<meta property={"og:description"} content={"Ласкаво просимо до InkMasters Tattoo Studio: де мистецтво стає частиною вас"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-5.jpg?v=2024-08-12T11:23:52.262Z) bottom scroll"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Ласкаво просимо до InkMasters Tattoo Studio: де мистецтво стає частиною вас
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Structure cells-number-total="3" cells-number-group="3">
			<Override slot="Content" grid-template-columns="9fr 3fr" md-grid-template-columns="repeat(6, 2fr)" sm-grid-template-columns="12fr">
				<Override
					slot="Cell 0th"
					grid-column="1 / auto"
					grid-row="auto / span 2"
					md-grid-column="1 / span 6"
					md-grid-row="span"
					sm-grid-column="auto"
					sm-grid-row="span"
					justify-self="center"
					align-self="center"
				/>
				<Override slot="Cell 1st" md-grid-column="1 / span 3" sm-grid-column="auto" />
				<Override slot="Cell 2nd" md-grid-column="4 / span 3" sm-grid-column="auto" />
				<Override slot="cell-0">
					<Text
						margin="0px 0px 24px 0px"
						color="#ffffff"
						font="--headline2"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						text-transform="uppercase"
					>
						Чому обирають InkMasters?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--light"
						text-align="left"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="0px 0px 32px 0px"
						max-width="70%"
					>
						Наші художники є справжніми майстрами своєї справи, з багаторічним досвідом і різноманітними стилями, щоб задовольнити будь-які ваші вподобання. Від делікатних лінійних робіт до сміливих, складних дизайнів – у нас є експертиза для створення ідеального татуювання для вас.
						<br />
						<br />
						Ми віримо в співпрацю. Наші художники тісно співпрацюють з вами, щоб розробити дизайн, який буде унікальним і відповідатиме вашій індивідуальності. Кожне татуювання, яке ми створюємо, є неповторним і виготовляється з увагою до деталей.
					</Text>
				</Override>
				<Override slot="cell-1">
					<Text
						margin="32px 0px 16px 0px"
						font="--base"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
					>
						Ваше здоров'я і безпека – наші головні пріоритети. Ми дотримуємося найвищих стандартів гігієни і використовуємо лише високоякісні, безпечні для шкіри фарби та обладнання.
					</Text>
				</Override>
				<Override slot="cell-2">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Наш студія створена, щоб бути комфортним та надихаючим місцем, де ви зможете розслабитися і насолодитися процесом татуювання. Ми прагнемо зробити кожен ваш візит особливим досвідом.
					</Text>
				</Override>
				{"                            "}
			</Override>
		</Structure>
		<Section padding="90px 0 120px 0" background="#0B0008" quarkly-title="Team-15">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 50px 0px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					sm-padding="0px 0 0px 0px"
					sm-margin="0px 0px 15px 0px"
				>
					<Text margin="0px 0px 25px 0px" color="--orange" font="normal 300 20px/1.2 --fontFamily-mono">
						Наші Художники
					</Text>
					<Text margin="0px 0px 30px 0px" color="--light" font="normal 900 48px/1.2 --fontFamily-sans">
						СЕРЦЕ INKMASTERS
					</Text>
					<Text margin="0px 0px 40px 0px" color="--lightD2" font="300 18px/140% --fontFamily-sansHelvetica">
						У InkMasters Tattoo Studio наші художники є душею нашого бізнесу. Кожен з них приносить унікальний набір навичок і художнього бачення, що дозволяє нам пропонувати широкий спектр стилів татуювань.
					</Text>
					<Button background="--color-orange" padding="15px 44px 15px 44px" font="normal 400 14px/1.5 --fontFamily-sansHelvetica">
						НАШІ ПОСЛУГИ
					</Button>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/img_3532-scaled.jpg?v=2024-08-14T08:02:33.604Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/img_3532-scaled.jpg?v=2024-08-14T08%3A02%3A33.604Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/img_3532-scaled.jpg?v=2024-08-14T08%3A02%3A33.604Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/img_3532-scaled.jpg?v=2024-08-14T08%3A02%3A33.604Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/img_3532-scaled.jpg?v=2024-08-14T08%3A02%3A33.604Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/img_3532-scaled.jpg?v=2024-08-14T08%3A02%3A33.604Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/img_3532-scaled.jpg?v=2024-08-14T08%3A02%3A33.604Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/img_3532-scaled.jpg?v=2024-08-14T08%3A02%3A33.604Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						АННА СТАНІСЛАВ
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Майстер портретних татуювань
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/QR5Q_V_gzQN933uZAjIActcdmHirl93xBsEDfnlQTrPFQ0Jk5l665m7EcqWMlDbbBWd1RWTj0J85i1QbXZfnw9ib.jpg?v=2024-08-14T08:02:33.599Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/QR5Q_V_gzQN933uZAjIActcdmHirl93xBsEDfnlQTrPFQ0Jk5l665m7EcqWMlDbbBWd1RWTj0J85i1QbXZfnw9ib.jpg?v=2024-08-14T08%3A02%3A33.599Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/QR5Q_V_gzQN933uZAjIActcdmHirl93xBsEDfnlQTrPFQ0Jk5l665m7EcqWMlDbbBWd1RWTj0J85i1QbXZfnw9ib.jpg?v=2024-08-14T08%3A02%3A33.599Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/QR5Q_V_gzQN933uZAjIActcdmHirl93xBsEDfnlQTrPFQ0Jk5l665m7EcqWMlDbbBWd1RWTj0J85i1QbXZfnw9ib.jpg?v=2024-08-14T08%3A02%3A33.599Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/QR5Q_V_gzQN933uZAjIActcdmHirl93xBsEDfnlQTrPFQ0Jk5l665m7EcqWMlDbbBWd1RWTj0J85i1QbXZfnw9ib.jpg?v=2024-08-14T08%3A02%3A33.599Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/QR5Q_V_gzQN933uZAjIActcdmHirl93xBsEDfnlQTrPFQ0Jk5l665m7EcqWMlDbbBWd1RWTj0J85i1QbXZfnw9ib.jpg?v=2024-08-14T08%3A02%3A33.599Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/QR5Q_V_gzQN933uZAjIActcdmHirl93xBsEDfnlQTrPFQ0Jk5l665m7EcqWMlDbbBWd1RWTj0J85i1QbXZfnw9ib.jpg?v=2024-08-14T08%3A02%3A33.599Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/QR5Q_V_gzQN933uZAjIActcdmHirl93xBsEDfnlQTrPFQ0Jk5l665m7EcqWMlDbbBWd1RWTj0J85i1QbXZfnw9ib.jpg?v=2024-08-14T08%3A02%3A33.599Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						ОЛЕГ МАРКОВИЧ
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Майстер абстрактних татуювань
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/%D0%BD%D0%B0-%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D1%83%D1%8E-960x960.jpg?v=2024-08-14T08:02:33.594Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/%D0%BD%D0%B0-%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D1%83%D1%8E-960x960.jpg?v=2024-08-14T08%3A02%3A33.594Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/%D0%BD%D0%B0-%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D1%83%D1%8E-960x960.jpg?v=2024-08-14T08%3A02%3A33.594Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/%D0%BD%D0%B0-%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D1%83%D1%8E-960x960.jpg?v=2024-08-14T08%3A02%3A33.594Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/%D0%BD%D0%B0-%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D1%83%D1%8E-960x960.jpg?v=2024-08-14T08%3A02%3A33.594Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/%D0%BD%D0%B0-%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D1%83%D1%8E-960x960.jpg?v=2024-08-14T08%3A02%3A33.594Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/%D0%BD%D0%B0-%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D1%83%D1%8E-960x960.jpg?v=2024-08-14T08%3A02%3A33.594Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/%D0%BD%D0%B0-%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D1%83%D1%8E-960x960.jpg?v=2024-08-14T08%3A02%3A33.594Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						ДМИТРО ШЕВЧЕНКО
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Майстер традиційних татуювань
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/5d2a23e547d4df788f8acf7193b5ff1e.jpg?v=2024-08-14T08:02:33.593Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/5d2a23e547d4df788f8acf7193b5ff1e.jpg?v=2024-08-14T08%3A02%3A33.593Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/5d2a23e547d4df788f8acf7193b5ff1e.jpg?v=2024-08-14T08%3A02%3A33.593Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/5d2a23e547d4df788f8acf7193b5ff1e.jpg?v=2024-08-14T08%3A02%3A33.593Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/5d2a23e547d4df788f8acf7193b5ff1e.jpg?v=2024-08-14T08%3A02%3A33.593Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/5d2a23e547d4df788f8acf7193b5ff1e.jpg?v=2024-08-14T08%3A02%3A33.593Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/5d2a23e547d4df788f8acf7193b5ff1e.jpg?v=2024-08-14T08%3A02%3A33.593Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/5d2a23e547d4df788f8acf7193b5ff1e.jpg?v=2024-08-14T08%3A02%3A33.593Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						ОЛЕНА МАКСИМОВА
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Майстер геометричних татуювань
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/evgeniy-tattoo-master.jpg?v=2024-08-14T08:02:33.590Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/evgeniy-tattoo-master.jpg?v=2024-08-14T08%3A02%3A33.590Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/evgeniy-tattoo-master.jpg?v=2024-08-14T08%3A02%3A33.590Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/evgeniy-tattoo-master.jpg?v=2024-08-14T08%3A02%3A33.590Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/evgeniy-tattoo-master.jpg?v=2024-08-14T08%3A02%3A33.590Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/evgeniy-tattoo-master.jpg?v=2024-08-14T08%3A02%3A33.590Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/evgeniy-tattoo-master.jpg?v=2024-08-14T08%3A02%3A33.590Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1efe778710018d2877e/images/evgeniy-tattoo-master.jpg?v=2024-08-14T08%3A02%3A33.590Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						АРТЕМ КОВАЛЬ
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Майстер кольорових татуювань
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				width="100%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					font="--headline2"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
					text-transform="uppercase"
				>
					Процес створення: від бачення до реальності
				</Text>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="40px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					У InkMasters Tattoo Studio ми з гордістю супроводжуємо вас через увесь процес татуювання, гарантуючи, що кінцевий результат буде чимось, що ви будете цінувати на все життя.
					<br />
					<br />
					Перша консультація: ваша подорож починається з консультації, де ви зустрічаєтеся з одним із наших художників, щоб обговорити ваші ідеї. Незалежно від того, чи є у вас чітка концепція або ви потребуєте творчого вкладу, ми допоможемо оформити ваше бачення.
					<br />
					<br />
					Розробка дизайну: на основі ваших побажань наш художник створить індивідуальний дизайн, адаптований саме для вас. Ви матимете можливість переглянути дизайн, надати відгуки та внести необхідні коригування перед початком татуювання.
					<br />
					<br />
					Сеанс татуювання: у день вашої зустрічі ви потрапите в чисте та професійне середовище, де відбудеться процес татуювання. Наші художники використовують новітні техніки та високоякісні матеріали, щоб забезпечити приголомшливий результат.
					<br />
					<br />
					Інструкції щодо догляду: ми віримо, що догляд за татуюванням є таким же важливим, як і процес його створення. Ми надамо вам детальні інструкції, щоб ваше татуювання загоїлося належним чином і зберігало яскравий вигляд з часом.
				</Text>
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-1.jpg?v=2024-08-12T11:23:52.251Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Готові перетворити ваше бачення в реальність?
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Не чекайте більше, щоб отримати татуювання, про яке мріяли. Забронюйте зустріч у InkMasters Tattoo Studio сьогодні і дозвольте нашим художникам створити щось по-справжньому особливе для вас.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});